import { Modal } from "bootstrap";
import data from "./data";

// Ready
document.addEventListener("DOMContentLoaded", function () {
	/*
	Mapa desktop
	*/
	const btnsMapaD = document.querySelectorAll(".mapa-d .mapa-cuaresma path");
	const btnsMapaM = document.querySelectorAll(".mapa-m ol li button");

	const modalElement = document.getElementById("modal");
	const modalTitle = modalElement.querySelector(".modal-title");
	const modalBadge = modalElement.querySelector(".badge");
	const videoFrame = modalElement.querySelector("#videoIframe");
	const closeModal = modalElement.querySelector(".btn-close");

	const modal = new Modal(modalElement, {
		backdrop: "static",
		keyboard: false,
		focus: true,
	});

	btnsMapaD.forEach((btn) => {
		btn.addEventListener("click", () => {
			let tag = btn.classList[1].slice(7);
			videoFrame.src = `https://www.youtube.com/embed/${data[tag].video}`;
			modalTitle.textContent = data[tag].name;
			modalBadge.textContent = data[tag].cite ? data[tag].cite : "";
			modal.show();
		});
	});

	btnsMapaM.forEach((btn) => {
		btn.addEventListener("click", () => {
			const tag = btn.dataset.click;
			videoFrame.src = `https://www.youtube.com/embed/${data[tag].video}`;
			modalTitle.textContent = data[tag].name;
			modalBadge.textContent = data[tag].cite ? data[tag].cite : "";
			modal.show();
		});
	});

	closeModal.addEventListener("click", () => {
		videoFrame.src = "";
		modal.hide();
	});
});
