const data = [
	{},
	{
		name: "Dia 1 - Miercoles de Ceniza",
		video: "C52y2GCukOI",
		cite: "Mt. 6, 1-6. 16-18",
	},
	{ name: "Día 2 - CONVERSIÓN", video: "npdiPGmx_4s", cite: "Lc.9,22-25" },
	{
		name: "Día 3 - FE-LIZ LA CULPA",
		video: "bcDgj9fF8Ls",
		cite: "Mt.9, 14-15",
	},
	{ name: "Día 4 - Dónde estás", video: "HDzZPSpK5EU", cite: "Lc.5,27-32" },
	{
		name: "Día 5 - TENTADO pero LEVANTADO",
		video: "QlihT5EmG80",
		cite: "Lc.4,1-13",
	},
	{ name: "Día 6 - AYUNO", video: "0xyakSaGyRw", cite: "Mt.25,31-46" },
	{ name: "Día 7 - LIMOSNA", video: "NPVuBLWZAbI", cite: "Mt.6,7-15" },
	{ name: "Día 8 - ORACIÓN", video: "EBtEQDcHNdI", cite: "Lc.11,29-32" },
	{
		name: "Día 9 - Mi alma te busca en silencio",
		video: "FYiil8lKKhU",
		cite: "Mt.7,7-12",
	},
	{
		name: "Día 10 - Silencio como retiro",
		video: "QuBufZS2PPU",
		cite: "Mt.5,20-26",
	},
	{
		name: "Día 11 - Silencio para conocerme",
		video: "gB2ceAqq9qU",
		cite: "Mt.5,43-48",
	},
	{
		name: "Día 12 - Humilad en la pobreza de espíritu",
		video: "h_S97Tubg0g",
		cite: "Lc.9,28b-36",
	},
	{
		name: "Día 13 - Humildad en la mansedumbre",
		video: "rcT1ZQezsXA",
		cite: "Lc.6,36-38",
	},
	{
		name: "Día 14 - Humilad en el sufrimiento",
		video: "J8Yc-6x9lQw",
		cite: "Mt.1,16. 18-21.24a",
	},
	{
		name: "Día 15 - Humilad en la justicia",
		video: "D1gA4eZvs4U",
		cite: "Mt.20,17-28",
	},
	{
		name: "Día 16 - Humildad en la misericordia",
		video: "FbsJLIC2X0A",
		cite: "Lc.16,19-31",
	},
	{
		name: "Día 17 - Humilad en la corazón limpio",
		video: "gnhMh1QCiR0",
		cite: "Mt.21,33-43.45-46",
	},
	{
		name: "Día 18 - Humilad en la PAZ",
		video: "8YLpThGfwLE",
		cite: "Lc.15,1-3.11-32",
	},
	{
		name: "Día 19 - El misterio de la libertad",
		video: "HhfsesQLZPQ",
		cite: "Lc.13,1-9",
	},
	{
		name: "Día 20 - Elegir lo que tu me das",
		video: "_gOgHU5btV0",
		cite: "Lc.1,26-38",
	},
	{
		name: "Día 21 - El desierto de la soberbia",
		video: "9I9fgcu6cWQ",
		cite: "Mt.18,21-35",
	},
	{
		name: "Día 22 - La libertad ante la autosuficiencia",
		video: "goYUta7Cp4E",
		cite: "Mt.5,17-19",
	},
	{
		name: "Día 23 - La libertad ante el egoísmo",
		video: "o5724fKjUKQ",
		cite: "Lc.11,14-23",
	},
	{
		name: "Día 24 - La libertad ante la comodidad",
		video: "HHWr9zgW-UQ",
		cite: "Mc.12,28b-34",
	},
	{
		name: "Día 25 - La libertad en el corazón de carne",
		video: "2AeWzXtKp40",
		cite: "Lc.18,9-14",
	},
	{
		name: "Día 26 - Salir de tu tierra",
		video: "hYQtBW9lwW0",
		cite: "Lc.15,1-3.11-32",
	},
	{
		name: "Día 27 - Descalzarse ante Dios",
		video: "wH9W1UGrDY8",
		cite: "Jn.4,43-54",
	},
	{
		name: "Día 28 - Yo seré tu Dios",
		video: "s0mLpFndYI0",
		cite: "Jn.5,1-16",
	},
	{
		name: "Día 29 - Dios elige el camino más largo",
		video: "6yQHBhnVMV0",
		cite: "Jn.5,17-30",
	},
	{
		name: "Día 30 - La FE del Silencio",
		video: "jCtsuKCfnIs",
		cite: "Lc.7,1-2.10.25-30",
	},
	{
		name: "Día 31 - Todo es posible para el que cree",
		video: "Ln5INNe7-V0",
		cite: "Jn.7,40-53",
	},
	{
		name: "Día 32 - El SÍ que cambió la historia",
		video: "sMN7PWGS5hc",
		cite: "Jn.7,40-53",
	},
	{
		name: "Día 33 - Contar estrellas",
		video: "qqkRFWlqxoE",
		cite: "Jn.8,1-11",
	},
	{
		name: "Día 34 - Cruzar el Mar Rojo",
		video: "XGSVjSlJ4wU",
		cite: "Jn.8,12-20",
	},
	{
		name: "Día 35 - Yo estaré contigo",
		video: "4udzRn2Kf4M",
		cite: "Jn.8,21-30",
	},
	{
		name: "Día 36 - Te enseñaré a caminar",
		video: "xLB9NWRde5w",
		cite: "Jn.8,31-42",
	},
	{ name: "Día 37 - Tú eres mío", video: "UwjZUswQg9g", cite: "Jn.8,51-59" },
	{
		name: "Día 38 - Tengo tu nombre tatuado",
		video: "OgKRcfrmET4",
		cite: "Jn.10,31-42",
	},
	{ name: "Día 39 - Solo por HOY", video: "zI2CSj1qNhc", cite: "Jn.11,45-57" },
	{ name: "Día 40 - Gritarán las piedras", video: "GjV5pTlzYiM", cite: "" },
];

export default data;
